import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [{
		path: '/',
		name: 'index',
		component: () => import('../views/index/index.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
