import {
	API
} from '../config/index.js'
import router from '../router'
import axios from 'axios'
import NProgress from 'nprogress'
import {
	ElMessageBox,
	ElMessage
} from 'element-plus'

import w_md5 from "./../js/md5.js"
import sj_time from "./../js/time.js"
import Number from "./../js/random.js"
import w_Sha from "./../js/sha1.js"
import crypto from "./../js/crypto.js"

let baseURL = API
const request = axios.create({
	baseURL: baseURL,
	// timeout: 100 * 1000
})

var times = sj_time.s_time()
var numbers = Number.sj_number()
var str = 'woshikuailexingqiukuaileyuanyuzixin' + times + numbers
var keys = w_md5(w_Sha.sha(str))

// 设置session级别的本地存储 存储基本url 用于上传组件的url拼接 方便以后替换
sessionStorage.setItem('baseURL', baseURL)

request.interceptors.request.use(
	config => {
		// var dataitem = config.data
		// if (config.data) {
		// 	config.data = {}
		// 	config.data.v = crypto.encrypt(JSON.stringify(dataitem))
			
		// }
		if (sessionStorage.token) {
			config.headers['token'] = sessionStorage.token
			config.headers['t'] = times
			config.headers['n'] = numbers
			config.headers['s'] = keys
		} else {
			config.headers['t'] = times
			config.headers['n'] = numbers
			config.headers['s'] = keys
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

request.interceptors.response.use(
	response => {
		NProgress.start()
		if (response.data.code == 1 || response.data.code == 200) {
			NProgress.done() // 进度条结束
			return response.data
		} else if (response.data.code == 10001) {
			sessionStorage.removeItem('token')
			ElMessage.error('凭证失效，请重新登录')
			setTimeout(function() {
				router.push('/login')
			}, 200)
			NProgress.done() // 进度条结束
		} 
		// else if (response.data.code == 203) {
		// 	sessionStorage.removeItem('token')
		// 	setTimeout(function() {
		// 		router.push('/login')
		// 	}, 200)
		// 	NProgress.done() // 进度条结束
		// }
		else {
			NProgress.done() // 进度条结束
			ElMessage.error(response.data.msg)
		}
	},
	error => {
		if (error.response.status === 401) {
			sessionStorage.removeItem('token')
			ElMessage.error('服务器内部错误,请重新登录')
		}
		if (error.response.status === 500) {
			sessionStorage.removeItem('token')
			ElMessage.error('服务器内部错误')
			setTimeout(function() {
				router.push('/login')
			}, 200)
		}
		if (error.response.status === 10001) {
			sessionStorage.removeItem('token')
			ElMessage.error('凭证失效，请重新登录')
			setTimeout(function() {
				router.push('/login')
			}, 200)
		}
		if (error.message === 'Network Error') {
			ElMessage.error('网络异常')
		}
		return Promise.reject(error)
	}
)

export default request
