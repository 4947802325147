<template>
  <div id="nav"></div>
  <router-view/>
</template>
<script>
	export default {
		data() {
			return {

			}
		},
		
		methods:{
			
		}
	}
</script>